<template>
    <div class="image-speed" data-scroll :data-scroll-speed="imageSpeed">
        <mouse-changer :type="mouseTypes.ImageScale">
            <div
                @click="itemClick($event, index)"
                @mouseover="hovered = true"
                @mouseleave="hovered = false"
                :class="{ hovered }"
                class="image-container left"
            >
                <img
                    :src="item.image"
                    :id="clickedTargetRef"
                    class="image"
                    alt=""
                />
                <div class="content">
                    <span class="title">{{ item.title }}</span>
                </div>
                <div class="shadow-container"></div>
            </div>
        </mouse-changer>
    </div>
</template>

<script>
import ImageScale from "@/mixins/ImageScale";
import MouseChanger from "@/components/custom/MouseChanger";
import { mapGetters } from "vuex";

export default {
    props: {
        item: {
            type: Object,
            default: () => {}
        },
        index: {
            type: Number,
            default: 1
        },
        mobileStop: {
            type: Boolean,
            default: false
        }
    },
    mixins: [ImageScale],
    data() {
        return {
            hovered: false,
            clicked: false
        };
    },
    components: {
        MouseChanger
    },
    computed: {
        ...mapGetters({
            device: "resize/geDevice"
        }),
        clickedTargetRef() {
            return `image-scale-${this.randomIntFromInterval(1, 500000)}`;
        },
        imageSpeed() {
            return this.device.size < 1024
                ? this.mobileStop
                    ? 0
                    : this.index / 2 + 1 + 0.2
                : this.index / 2 + 1 + 0.5;
        }
    },
    methods: {
        randomIntFromInterval(min, max) {
            return Math.floor(Math.random() * (max - min + 1) + min);
        }
    },
    mounted() {}
};
</script>

<style lang="scss" scoped>
$ease-out: cubic-bezier(0.07, 0.29, 0, 0.99);

.image-speed {
    .image-container {
        position: relative;
        overflow: hidden;
        transition: all 0.6s 0.2s $ease-out;
        clip-path: inset(30px);
        @include center-children;
        @media only screen and (max-width: 1023px) {
            clip-path: inset(15px);
        }

        .shadow-container {
            position: absolute;
            height: 100%;
            width: 100%;
            background: rgba(0, 0, 0, 0.2);
            pointer-events: none;
            opacity: 0;
            top: 0;
            left: 0;
            transition: opacity 0.3s $ease-out;
            clip-path: inset(30px);
            @media only screen and (max-width: 1023px) {
                clip-path: inset(15px);
                opacity: 1;
            }
        }

        &.hovered {
            .title,
            .shadow-container {
                opacity: 1;
                transform: translateY(0);
            }
        }

        .image {
            width: 100%;
            transition: clip-path 0.6s 0.2s $ease-out,
                transform 0.6s 0.2s $ease-out;
            transform: scale(1.2);
            clip-path: inset(30px);
            @media only screen and (max-width: 1023px) {
                clip-path: inset(15px);
            }
        }

        .content {
            position: absolute;
            bottom: 52px;
            left: 62px;
            overflow: hidden;
            pointer-events: none;
            @media only screen and (max-width: 1023px) {
                display: none;
                left: 30px;
            }
        }

        .title {
            position: relative;
            display: block;
            left: 0;
            top: 0;
            font-weight: bold;
            color: white;
            opacity: 0;
            transform: translateY(100%);
            transition: all 0.3s $ease-out;
        }
    }
}

.image-speed.observed /deep/ {
    .image {
        transform: scale(1);
    }
}
</style>
