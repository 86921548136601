<template>
    <div data-scroll :data-scroll-id="name" class="layout-8">
        <div v-observer class="content-container">
            <div class="text-content">
                <h2 :style="{ color: item.color }" class="title">
                    {{ item.contentTitle }}
                </h2>
                <div class="description" v-html="item.contentText"></div>
            </div>
            <image-scale :mobileStop="true" :item="item.imageItem" />
        </div>
        <div
            class="component-title"
            data-scroll
            :data-scroll-speed="imageSpeed"
            v-html="header"
        ></div>
    </div>
</template>

<script>
import first from "lodash/first";
import ImageScale from "@/components/custom/ImageScale";
import { mapGetters } from "vuex";

export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        config: {
            type: Object,
            default: () => {}
        },
        name: {
            type: String,
            default: ""
        }
    },
    components: {
        ImageScale
    },
    computed: {
        ...mapGetters({
            device: "resize/geDevice"
        }),
        item() {
            return first(
                this.data?.list?.map(item => {
                    let image = first(item?.image)?.devices?.[
                        this.device?.type
                    ];

                    return {
                        background: item.background_color,
                        image,
                        imageItem: {
                            image,
                            title: item.image_title || "Title",
                            teaser: item.teaser,
                            description: item.image_description
                        },
                        contentTitle: first(item?.content?.tabs)?.title,
                        contentText: first(item?.content?.tabs)?.content,
                        color: item.color1
                    };
                })
            );
        },
        header() {
            return this.config?.header?.title;
        },
        imageSpeed() {
            return this.device.size < 1024 ? 0 : 1.4;
        }
    }
};
</script>

<style lang="scss" scoped>
$ease-out: cubic-bezier(0.15, 0.16, 0.37, 1);

.layout-8 /deep/ {
    .content-container {
        display: flex;
        justify-content: space-between;
        font-size: 21px;
        color: #0a141c;
        text-align: left;
        padding: 144px 375px 0;
        @media only screen and (max-width: 1650px) and (min-width: 1440px) {
            padding: 144px 165px 0;
        }
        @media only screen and (max-width: 1439px) and (min-width: 1366px) {
            padding: 144px 128px 0;
        }
        @media only screen and (max-width: 1365px) and (min-width: 768px) {
            padding: 89px 47px 0;
        }
        @media only screen and (max-width: 767px) {
            padding: 55px 16px 0;
        }
        @media only screen and (max-width: 1023px) {
            flex-direction: column;
        }
        @media only screen and (max-width: 1650px) {
            font-size: 18px;
        }

        &.observed {
            .image-container {
                clip-path: inset(30px) !important;
                @media only screen and (max-width: 1023px) {
                    clip-path: inset(15px) !important;
                }

                img {
                    transform: scale(1);
                }
            }
        }

        .text-content {
            width: 50%;
            min-width: 50%;
            margin-top: 81px;
            @media only screen and (max-width: 1650px) and (min-width: 1366px) {
                margin-top: 101px;
            }
            @media only screen and (max-width: 1365px) and (min-width: 1024px) {
                margin-top: 52px;
            }
            @media only screen and (max-width: 1023px) {
                width: 100%;
                margin-top: 0;
            }

            .title {
                margin-top: 44px;
                @media only screen and (max-width: 1023px) and (min-width: 768px) {
                    margin-top: 34px;
                }
                @media only screen and (max-width: 767px) {
                    margin-top: 21px;
                }
            }

            .description {
                margin-top: 21px;
                font-family: "Noto-Sans", "Noto-Sans-georgian";
                font-weight: normal;
                font-size: 21px;
                line-height: 30px;
                text-align: left;
                color: #0a141c;
            }
        }

        .image-speed {
            @media only screen and (max-width: 1023px) {
                width: 100%;
            }
        }

        .image-container {
            width: 480px;
            clip-path: inset(30px 30px 45% 30px);
            @media only screen and (max-width: 1650px) and (min-width: 1366px) {
                width: 505px;
            }
            @media only screen and (max-width: 1365px) and (min-width: 1024px) {
                width: 460px;
            }
            @media only screen and (max-width: 1023px) {
                width: calc(100% + 30px);
                left: -15px;
                margin-top: 46px;
            }
            @media only screen and (max-width: 767px) {
                margin-top: 26px;
            }

            .content {
                left: 51px;

                .title {
                    font-weight: bold;
                    font-size: 16px;
                    letter-spacing: 0.03em;
                }
            }
        }
    }

    .component-title {
        font-size: 55px;
        text-align: center;
        margin: 100px auto 0;
        width: fit-content;
        max-width: 40%;
        @media only screen and (max-width: 1650px) and (min-width: 1366px) {
            font-size: 55px;
            margin: 89px auto 0;
        }
        @media only screen and (max-width: 1365px) and (min-width: 768px) {
            font-size: 34px;
            margin: 55px auto 0;
        }
        @media only screen and (max-width: 767px) {
            font-size: 21px;
            margin: 55px auto 0;
        }

        span {
            color: #e5b676;
        }
    }
}
</style>
